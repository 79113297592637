

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import Multiselect from "vue-multiselect";
import commanModal from "../CommonModals.vue";
// import HelpConfig from "../help24x7/HelpConfig.vue"
@Component({
  components: {
    Multiselect,
    commanModal,
  },
})
export default class ConfigureServices extends Vue {
  public serviceData: any = {
    serviceEmail: null,
    departmentId: [],
    empUserId: [],
  };

  public myData: any = null;
  public loading:boolean = true;
  public hasAccess:any = [];
  public departmentEmail: any = null;
  public serviceList:any = [];
  public departmentList: any = [];
  public departmentListArray: any = [];
  public employeeList:any = [];
  public count: any = 0;
  public action = "Add";
  public process: any = false;
  $lodash: any;
  $snotify: any;
  public async fetchDepartmentList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/fetchDepartmentDetails",
        {
          headers: authHeader(),
        }
      );
      let departmentdata = response.data.departmentDetail
        ? response.data.departmentDetail
        : [];

      departmentdata.forEach((element, i) => {
        this.myData = {
          departmentName: element.departmentName,
          departmentHeadUserId: element.departmentHeadUserId,
          _id: element._id,
        };
        this.departmentList.push(this.myData);
        this.departmentList.forEach((dept) => {
          this.employeeList.forEach((emp) => {
            if (emp.userId == dept.departmentHeadUserId) {
              dept.deptHead = emp.name;
            }
          });
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchEmployeeList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/getEmployeeList",
        {
          headers: authHeader(),
        }
      );
      this.employeeList = response.data ? response.data : [];
    } catch (error) {
      console.log(error);
    }
  }

  public clearData() {
    this.action = "Add";
    this.serviceData = {
      serviceType: null,
      departmentId: [],
      empUserId: [],
      serviceEmail: null,
    };
  }

  public async toAddService() {
    this.$modal.show("addService");
    this.clearData();
  }

  public editData(detail) {
    this.serviceData = JSON.parse(JSON.stringify(detail));
    // this.serviceData.serviceType = this.$lodash.startCase(
    //   this.serviceData.serviceType
    // );
    this.serviceData.departmentId._id = detail.departmentId._id;

    this.action = "Edit";
    this.$modal.show("addService");
  }

  public async closeModal() {
    this.process = false;
    this.$modal.hide("addService");
    this.fetchServiceList();
  }

  public async saveServiceDetails() {
    try {
      // this.departmentList.forEach((i) => {
      //   if (i._id == this.serviceData.departmentId) {
      //       this.serviceData.empUserId = i.departmentHeadUserId;

      //   }
      // });
      for (let i = 0; i < this.departmentList.length; i++) {
        if (this.departmentList[i]._id == this.serviceData.departmentId._id) {
          this.serviceData.empUserId =
            this.departmentList[i].departmentHeadUserId;
          // this.departmentListArray.push(this.serviceData.empUserId);
        }
      }

      this.process = true;
      let body = {
        data: this.serviceData,
        action: this.action,
      };
      // body.data.departmentId.departmentHeadUserId=body.data.departmentId.departmentHeadUserId;
      // if(body.data.departmentId.departmentHeadUserId==null)
      if (this.serviceData.empUserId == null) {
        this.$snotify.warning("Department head not found");
        this.process = false;
        this.$modal.hide("addService");
      } else {
        let response = await Axios.post(
          BASE_API_URL + "administrator/saveServiceDetails",
          body,
          {
            headers: authHeader(),
          }
        );
        if (response.data.sameName == true) {
          this.$snotify.warning("Service category name can not be same");
          this.process = false;
          return;
        }
        if (response.data.status == 200) {
          // if(body.departmentId.)
          this.$snotify.success(response.data.message);
        } else if (response.data.status == 300) {
          this.$snotify.warning(response.data.message);
        }
        this.process = false;

        if (response.data.status == 200) {
          this.$modal.hide("addService");
          this.fetchServiceList();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async fetchServiceList() {
    try {
      this.loading = true
      this.$store.state.fusionLoader = true
      this.$store.state.loaderTitleId = 1;
      let response = await Axios.get(
        BASE_API_URL + "administrator/fetchServiceList",
        {
          headers: authHeader(),
        }
      );

      this.serviceList = response.data ? response.data : [];
      this.serviceList.forEach((ser) => {
        this.departmentList.forEach((dept) => {
          if (ser.departmentId.departmentName == dept.departmentName) {
            ser.departmentdata=dept
          }
        });
      });
      this.$store.state.fusionLoader= false;
      this.loading = false;
    } catch (error) {
      this.$store.state.fusionLoader= false;
      this.loading = false;
      console.log(error);
    }
  }

  public async deleteService(id) {
    try {
      await this.$refs.ref["confirmationBox"]({
        msg: `Are you sure you want to delete this service?`,
        data: { id: id },
        loaderId: 4,
        apiPath: "administrator/deleteService",
      });
      
    } catch (error) {
      console.log(error);
    }
  }

  async beforeMount(){
    this.hasAccess = this.$store.state.sessionObject.crudRight ? this.$store.state.sessionObject.crudRight:{}
   
  }

  async mounted() {
    await this.fetchEmployeeList();

    await this.fetchDepartmentList();

    await this.fetchServiceList();

    // await this.clearData();
  }
}
